.agenda-item {
  display: flex;
  flex-direction: row;

  .time-slot {
    width: 20%;

    .primary-data-field {
      font-weight: 500;
      // font-size: 18px;
    }

    .secondary-data-field {
      font-size: 11px;
    }
  }

  .rest-slot {
    width: 80%;

    .topic-field {
      white-space: pre-wrap;
      font-size: 14px;
    }

    .speaker-field {
      font-size: 12px;
    }
  }
}
